import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import styles from './license-special-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <div className="content">
            <p className={styles.subheader}>Current Nurse Licensing Special</p>
            <p className="content">
              One of our travelers' favorite programs is back with NEW states!
              Right now there’s an extra push to get travelers working in WA,
              NY, PA, OR, MA, OH, CA, and IL. Jobs are moving quickly, and we
              need all hands on deck!
              <br />
              <br />
              Through January 1st, 2023, we'll not only reimburse you licensing
              costs, we'll also send you a <b>
                brand new iPad or Apple Watch
              </b>{' '}
              during your assignment in one of those eight states!
            </p>
            <Link to="/licensing-special/" className="button is-info">
              Learn more
            </Link>
            <br />
            <br />
            <p className={styles.subheader}>
              *New* Licensing Special for Allied Travelers
            </p>
            <p className="content">
              Now our allied travelers can join in the fun as well! Through
              January 1st, 2023, we'll not only reimburse you licensing costs,
              we'll also send you a <b>brand new iPad or Apple Watch</b> during
              your assignment in one a qualifying state. Click below for details
              on qualifying modalities.
            </p>
            <Link to="/allied-licensing-special/" className="button is-info">
              Learn more
            </Link>
          </div>
        </div>
        <div
          className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile}`}
        >
          <Img
            fluid={fluid}
            className={styles.aboutImageWrapper}
            alt="licensing special offer"
          />
        </div>
      </div>
    </div>
  );
};
