import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import styles from './license-reimbursement.module.scss';
import HeroImage from '../../components/hero-image/hero-image';
import { GatsbyImg } from '../../services/img-location/img-location-service';
import LicenseOverviewSection from '../../components/travel-nursing/licensing/license-overview-section';
import LicenseSpecialSection from '../../components/travel-nursing/licensing/license-special-section';
import LicenseCaliSection from '../../components/travel-nursing/licensing/license-cali-section';
import { Helmet } from 'react-helmet';

export default ({ data }) => {
  const getLicenseReimbursementImageEdge = name => {
    return data.allFile.edges.find(e => e.node.name === name);
  };
  const gatsybyOgImg = new GatsbyImg('license-reimbursement-og', data);
  const gatsbyHeroImg = new GatsbyImg('license-reimbursement', data);
  return (
    <>
      <Helmet>
        <title>Nursing license reimbursement - Trustaff</title>
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="Not licensed in a state where you want to travel? Trustaff can help. One of the many benefits available to our travel nurses is assistance obtaining your nursing license in a new state."
        />
        <meta property="og:title" content="Nursing license reimbursement" />
        <meta
          property="og:description"
          content="Not licensed in a state where you want to travel? Trustaff can help. One of the many benefits available to our travel nurses is assistance obtaining your nursing license in a new state."
        />
        <meta property="og:image" content={gatsybyOgImg.ogImgUrl} />
        <meta
          property="og:url"
          content="https://www.trustaff.com/travel-nursing/license-reimbursement/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Trustaff" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@Trustaff" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout>
        <HeroImage fluid={gatsbyHeroImg.fluidImg}>
          <div className={'columns'}>
            <div className="column is-7 is-offset-1">
              <div className={styles.heroTextArea}>
                <h1 className={`title is-2 has-text-white`}>
                  Nursing license reimbursement
                </h1>
                <p className={`content`}>
                  Not licensed in a state where you want to travel?{' '}
                  <b>Trustaff can help.</b> One of the many benefits available
                  to our travel nurses is assistance obtaining your nursing
                  license in a new state.
                </p>
                <p className={`content`}>
                  Travel nurses who are licensed in multiple states (or who have
                  their Compact Nursing License) have more options when
                  considering their next assignment.
                </p>
              </div>
            </div>
          </div>
        </HeroImage>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <LicenseOverviewSection
            fluid={
              getLicenseReimbursementImageEdge(
                'license-reimbursement-sidebar-1'
              ).node.childImageSharp.fluid
            }
          />
        </div>
        <div
          className={`section ${styles.sectionNoSidePaddingMobile} ${styles.sectionBackgroundGrey}`}
        >
          <LicenseSpecialSection
            fluid={
              getLicenseReimbursementImageEdge(
                'license-reimbursement-sidebar-2'
              ).node.childImageSharp.fluid
            }
          />
        </div>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <LicenseCaliSection
            fluid={
              getLicenseReimbursementImageEdge(
                'license-reimbursement-sidebar-3'
              ).node.childImageSharp.fluid
            }
          />
        </div>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  {
    allFile(filter: { relativePath: { regex: "/^license-reimbursement/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
