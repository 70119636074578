import React from 'react';
import Img from 'gatsby-image';
import styles from './license-cali-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">
            We wish they ALL could be California travelers
          </h3>
          <div className="content">
            <p className={`content`}>
              Home to everything from celebrity-filled cities to beautiful
              mountains, redwoods, and beaches (and with the <b>best pay</b> and
              even better patient ratios), California is one of our traveler's
              favorite places to work.
            </p>
            <p className={styles.subheader}>
              Let Trustaff help expedite your California nursing license
            </p>
            <p className="content">
              If you don't have your California nursing license yet, we can
              help. We've helped hundreds of travelers like you through the
              process. We know the ins and outs of the process and can help you
              complete all the correct licensing requirements in order to
              receive your license as quickly as possible and we'll reimburse
              you for your costs!
            </p>
          </div>
        </div>
        <div
          className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile}`}
        >
          <Img
            fluid={fluid}
            className={styles.aboutImageWrapper}
            alt="travel nursing housing"
          />
        </div>
      </div>
    </div>
  );
};
