import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styles from './license-overview-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">
            Take your talents somewhere new
          </h3>
          <div className="content">
            <p className="content">
              With new <Link to="/jobs/">travel nursing jobs</Link> opening
              every day in all 50 states, we want you to have the flexibility to
              go wherever the wind (and the paycheck) takes you.
            </p>
            <p className="content">
              If you are interested in taking an assignment in a state where you
              don’t have a current license, not only will your recruiting team
              help make the process as easy as possible, we’ll{' '}
              <b>reimburse you</b> for your fees.
            </p>
            <p className="content">
              Each state has a different licensing process. While some new
              licenses can be as quick as a few days, some can take a few weeks
              or even months. Learn more about individual{' '}
              <Link to="/jobs/">state licensing requirements</Link>
            </p>
          </div>
          <Link to="/apply/" className="button is-primary">
            Get started
          </Link>
        </div>
        <div
          className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile}`}
        >
          <Img
            fluid={fluid}
            className={styles.aboutImageWrapper}
            alt="nursing license reimbursement"
          />
        </div>
      </div>
    </div>
  );
};
